.container {
  margin: 0 8.5% 0 8.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footerContainerFailure {
  margin-top: auto;
}
