.failure-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.succes-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title h2 {
  text-align: center;
}

.imgContainer {
  display: flex;
  justify-content: center;
  position: relative;
}

.successIcon {
  position: absolute;
  width: 48px;
  height: 48px;
  left: calc(50% + 15px);
  top: -6px;
}

.imgContainer .productIcon {
  height: 125px;
  width: 125px;
}
.infContainer {
  display: flex;
  flex-direction: column;
}
.color {
  color: #e20074;
}

.succesContainer {
  min-height: calc(100vh - 120px);
}
