.contentSelectProd {
  margin-bottom: 20px;
}

.grid-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 20px;
  row-gap: 20px;
  column-gap: 10px;
}

.select-prod--page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
