.header-container {
  background-color: #e20074;
  color: #ffffff;
  height: 60px;
}

.logo-text-container {
  display: flex;
  justify-content: flex-start;
  padding: 10px 0 10px 0;
  align-items: center;
}

.header-text {
  font-family: TeleNeo;
  margin-left: 30px;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  margin-left: 47px;
}
