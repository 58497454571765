.footerContainer {
  height: 100px;
  background-color: rgb(38, 38, 38);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-family: "TeleNeo", Calibri, "PT Sans Narrow", sans-serif;
  line-height: 2;
  letter-spacing: 0.02em;
  font-weight: 400;
  line-height: 1.43px;
  max-height: 70px;

  width: 100%;
  height: 60px;
}

.footerContainer {
  font-family: TeleNeo;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}
