.product-card--container {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.product-card--info {
  display: flex;
  align-items: center;
}

.product-card--info-right {
}
.product-card--category {
  color: #e20074;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}
.product-card--region {
  color: #999999;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 11px;
  margin-left: 11px;
}
.product-card--trial {
  font-style: normal;
  font-weight: 800;
  font-size: 10px;
  line-height: 11px;
  color: #999999;
}
.product-card--name {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #444444;
}
.product-card--tag-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
  column-gap: 8px;
  margin-top: 12px;
}
.product-card--tag {
  display: flex;
  align-items: center;
  padding: 2px 8px;
  background: #dddddd;
  border-radius: 9px;
}
.product-card--tag-name {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}
