.titleSection {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e20074;
  flex-direction: column;
}

.titleSection #productName,
.titleSection #productDescription {
  color: white;
}

.titleSection #productName {
  text-align: center;
}
.titleSection #regionAndTrial {
  color: white;
  margin-top: 16px;

  font-size: 12px;
  line-height: 14px;
}

.container #totalCharges {
  color: #e20074;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 21px;
  margin-top: 28px;
}
.priceSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.priceSection .price {
  color: #e20074;
  font-weight: bold;
}

.priceSection .priceLabel {
  font-family: TeleNeo;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}

.taxesInfo {
  margin-top: 20px;
  margin-bottom: 20px;
}

.taxesInfo p {
  font-family: TeleNeo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
  margin: 0;
}

.contactSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.contactSection h4 {
  margin: 0;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 18px;
  color: #999999;
}
.contactSection .contactInfo {
  font-family: TeleNeo;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 18px;
  color: #999999;
}

.contactSection .contactInfo img {
  margin-right: 10px;
}

.contactInfo {
  font-family: TeleNeo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
}
.agreementSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.switchInfo {
  display: flex;
}
.switchInfo .info {
  margin-left: 23px;
}

.subButton {
  min-width: 64px;
  min-height: 42px;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: TeleNeo;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  border-style: none;
  margin-top: 8px;
  font-size: 14px;
  line-height: 16px;
}

.subButton:active {
  background-color: #c20064 !important;
  box-shadow: 0 1px #666;
  transform: translateY(1px);
}

.freeTrialButton {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px 16px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  border-style: none;
  margin-top: 34px;
  border: 1px solid #e20074;
  color: #e20074;
  background-color: white;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  font-family: TeleNeo;
}
.color {
  color: #e20074;
}
.cancelButton {
  font-family: TeleNeo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  border: none;
  background-color: white;
  color: #e20074;
  margin-top: 8px;
  margin-bottom: 18px;
}
.mt-28 {
  margin-top: 28px;
}

.buttonSize {
  width: 260px;
  height: 44px;
}

/* Switch Button */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 19px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #c20064;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.product-page--tag-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
  column-gap: 8px;
  margin-top: 16px;
  margin-bottom: 20px;
}
.product-page--tag {
  display: flex;
  align-items: center;
  padding: 2px 8px;
  background: #ffffff;
  border-radius: 12px;
}
.product-page--tag-name {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
